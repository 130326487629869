import React, { Component, Fragment } from "react";
import classnames from "classnames";

import DownArrow from "../components/img/DownArrow";
import PostLink from "../components/PostLink";
import YoutubePlayerStandalone from "../components/video/YoutubePlayerStandalone";
import BackgroundImage from "../components/BackgroundImage";
import ContactForm from "../components/ContactForm";
import LocationMap from "../components/LocationMap";

import withLayout from "./withLayout";

import scrollIcon from "../img/scroll-icon.gif";

const LongFormHeader = ({
  image,
  icon,
  title,
  subTitle,
  description,
  scrollClick,
}) => {
  return (
    <header className="long-form-header">
      <div className="long-form-header__image">
        {image && (
          <BackgroundImage
            thumbnailUrl={image.sizes.medium}
            imageUrl={image.url}
            alt={image.alt}
          />
        )}
      </div>
      <div className="long-form-header__info">
        {icon && (
          <img
            className="long-form-header__icon"
            src={icon.url}
            alt={icon.alt}
          />
        )}
        {title && (
          <h1 className="long-form-header__title">
            {title}
            {subTitle && (
              <span className="long-form-header__sub-title">
                <br />
                {subTitle}
              </span>
            )}
          </h1>
        )}
        {description && (
          <p className="long-form-header__description">{description}</p>
        )}
        <button
          className="long-form-header__scroll-button"
          onClick={scrollClick}
        >
          <img
            src={scrollIcon}
            alt="scroll down for more"
            className="long-form-header__scroll-icon"
          />
        </button>
      </div>
    </header>
  );
};

const FeatureSection = (section) => {
  const {
    title,
    textContent,
    includeLink,
    linkPage,
    linkSection,
    linkText,
    imagevideo,
    image,
    video,
    videoCoverImage,
  } = section;
  const isImage = imagevideo === "image";
  const isVideo = imagevideo === "video";

  return (
    <React.Fragment>
      <div
        className={classnames(
          "long-form-feature__image",
          `long-form-feature__image--${imagevideo}`
        )}
      >
        {isImage && image && (
          <div className="long-form-feature__image-inner-wrap">
            <BackgroundImage
              thumbnailUrl={image.sizes.thumbnail}
              imageUrl={image.url}
              alt={image.alt}
            />
          </div>
        )}
        {isVideo && video && (
          <YoutubePlayerStandalone
            oembed={video}
            coverImage={videoCoverImage}
          />
        )}
      </div>
      <div className="long-form-feature__text">
        <h2 className="long-form-feature__title">{title}</h2>
        <p
          className="long-form-feature__content-text"
          dangerouslySetInnerHTML={{ __html: textContent }}
        />
        {includeLink && linkPage && (
          <div className="long-form-feature__link-wrapper">
            <PostLink
              {...linkPage}
              ext={linkSection}
              className="long-form-feature__link"
            >
              {linkText || "Find out more"}
            </PostLink>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
const BannerSection = (section) => {
  return (
    <Fragment>
      <p
        className="long-form-banner__text"
        dangerouslySetInnerHTML={{ __html: section.textContent }}
      />
      {section.bannerAuthor && (
        <figcaption className="long-form-banner__caption">
          <span className="long-form-banner__author">
            {section.bannerAuthor}
          </span>
          {section.bannerCompany && (
            <span className="long-form-banner__company">
              {section.bannerCompany}
            </span>
          )}
        </figcaption>
      )}
      <p />
    </Fragment>
  );
};

const LongFormFooter = (props) => {
  const { contactPage: data, settings, product } = props;
  let mapsLocation;
  if (data.location) {
    mapsLocation = {
      lat: parseFloat(data.location.lat),
      lng: parseFloat(data.location.lng),
    };
  }

  return (
    <div className="long-form-footer">
      <div className="long-form-footer__section-wrap">
        <div className="long-form-footer__section">
          <h2 className="long-form-footer__section-title">Get in Touch</h2>
          <ContactForm placeholderText={product.contactFormPlaceholder} />
        </div>
        <div className="long-form-footer__section">
          <h2 className="long-form-footer__section-title">
            {data.addressTitle}
          </h2>
          <div className="p--sm long-form-footer__address">
            <div dangerouslySetInnerHTML={{ __html: data.address }} />
            <a href={`tel:${data.telephoneNumber}`}>{data.telephoneNumber}</a>
          </div>
          <div className="long-form-footer__map">
            {mapsLocation && (
              <LocationMap
                location={mapsLocation}
                apiKey={settings.googleApiKey}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

class LongForm extends Component {
  constructor(props) {
    super();
    const { data, match } = props;

    const product = data[match.params.articleTitle];
    this.myRefs = {
      "get-in-touch": React.createRef(),
    };
    this.underFold = React.createRef();
    this.state = { pageYOffset: 0 };

    if (product) {
      product.sections.forEach(({ sectionId }) => {
        console.log({ sectionId });
        if (sectionId) {
          this.myRefs[sectionId] = React.createRef();
        }
      });
    }
  }
  scrollToSection(sectionId) {
    const ref = this.myRefs[sectionId];
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }
  onScroll = () => {
    this.setState({ pageYOffset: window.pageYOffset });
  };
  scrollClick = () => {
    if (this.underFold.current) {
      this.underFold.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    const { match } = this.props;
    const sectionId = match.params.sectionId;
    if (sectionId) {
      this.scrollToSection(sectionId);
    }
    this.onScroll();
    window.addEventListener("scroll", this.onScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }
  componentDidUpdate(prevProps) {
    const { match } = this.props;
    const sectionId = match.params.sectionId;
    if (sectionId && sectionId !== prevProps.match.params.sectionId) {
      this.scrollToSection(sectionId);
    } else if (match.url !== prevProps.match.url && window) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    const { data, match } = this.props;

    const product = data[match.params.articleTitle];

    if (!product) {
      return <main className="long-form long-form--404">404, not found</main>;
    }

    const shouldHideFab =
      parseInt(this.state.pageYOffset) === 0 ||
      window.innerHeight + this.state.pageYOffset >=
        document.body.offsetHeight -
          this.myRefs["get-in-touch"].current.clientHeight;

    return (
      <main className="long-form">
        <button
          className={classnames("long-form__get-in-touch-fab", {
            "long-form__get-in-touch-fab--hidden": shouldHideFab,
          })}
          onClick={() => this.scrollToSection("get-in-touch")}
        >
          Get in touch{" "}
          <span className="long-form__get-in-touch-fab-icon">
            <DownArrow />
          </span>
        </button>
        <LongFormHeader {...product} scrollClick={this.scrollClick} />
        <div className="long-form__features">
          <div className="scroll-anchor" ref={this.underFold} />
          {product.sections.map((section) => {
            if (section.banner) {
              return (
                <figure className="long-form-banner">
                  <div
                    className="scroll-anchor"
                    ref={this.myRefs[section.sectionId]}
                  />
                  <BannerSection {...section} />
                </figure>
              );
            }
            return (
              <section className="long-form-feature">
                <div
                  className="scroll-anchor"
                  ref={this.myRefs[section.sectionId]}
                />
                <FeatureSection {...section} />
              </section>
            );
          })}
        </div>
        <div ref={this.myRefs["get-in-touch"]}>
          <LongFormFooter {...this.props} product={product} />
        </div>
      </main>
    );
  }
}

export default withLayout(LongForm, {
  template: "long-form",
  footer: { sticky: true, lightText: true },
  header: { hasGradient: false },
});
