import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import SubMenu from "../header/SubMenu";
import { legalMenu } from "../Footer";

import Instagram from "../img/Instagram";
import Twitter from "../img/Twitter";

const linkClass = ({ active }) => {
  return classNames({
    "mobile-menu__link": true,
    "mobile-menu__link--active": active,
  });
};

class MobileMenu extends Component {
  constructor() {
    super();

    this.state = {
      applicationMenuOpen: false,
      productMenuOpen: false,
      legalMenuOpen: false,
    };

    this.toggleProductMenu = this.toggleProductMenu.bind(this);
    this.toggleApplicationMenu = this.toggleApplicationMenu.bind(this);
    this.toggleLegalMenu = this.toggleLegalMenu.bind(this);
  }

  toggleProductMenu() {
    this.setState({ productMenuOpen: !this.state.productMenuOpen });
  }

  toggleApplicationMenu() {
    this.setState({ applicationMenuOpen: !this.state.applicationMenuOpen });
  }

  toggleLegalMenu() {
    this.setState({ legalMenuOpen: !this.state.legalMenuOpen });
  }

  render() {
    const {
      productsMenu,
      solutionsMenu,
      menuOpen,
      settings,
      legal,
    } = this.props;

    const { productMenuOpen, applicationMenuOpen, legalMenuOpen } = this.state;

    const { instagramUrl, twitterUrl } = settings;

    const subMenuOpen = productMenuOpen || applicationMenuOpen || legalMenuOpen;

    const menuClass = classNames("mobile-menu", {
      "mobile-menu--open": menuOpen,
      "mobile-menu--sub-menu-open": subMenuOpen,
    });

    const productMenuClass = classNames("mobile-menu__sub-menu", {
      "mobile-menu__sub-menu--open": productMenuOpen,
    });

    const applicationMenuClass = classNames("mobile-menu__sub-menu", {
      "mobile-menu__sub-menu--open": applicationMenuOpen,
    });

    const legalMenuClass = classNames("mobile-menu__sub-menu", {
      "mobile-menu__sub-menu--open": legalMenuOpen,
    });

    return (
      <nav className={menuClass}>
        <div className="mobile-menu__site-nav">
          <button
            onClick={this.toggleProductMenu}
            className={linkClass({ active: productMenuOpen })}
          >
            Products
          </button>
          <div className={productMenuClass}>
            {!productsMenu ? (
              "Loading menu ..."
            ) : (
              <SubMenu
                parentClass="mobile-menu"
                items={productsMenu.items}
                isOpen={this.state.productMenuOpen}
              />
            )}
          </div>
          <button
            onClick={this.toggleApplicationMenu}
            className={linkClass({ active: applicationMenuOpen })}
          >
            Solutions
          </button>
          <div className={applicationMenuClass}>
            {!solutionsMenu ? (
              "Loading menu ..."
            ) : (
              <SubMenu
                parentClass="mobile-menu"
                items={solutionsMenu.items}
                isOpen={this.state.applicationMenuOpen}
              />
            )}
          </div>
          <Link
            to="/casestudies"
            data-link-text="Case Studies"
            className={linkClass({})}
          >
            Case Studies
          </Link>
          <Link to="/news" data-link-text="News" className={linkClass({})}>
            News
          </Link>
          <Link to="/blog" data-link-text="Blog" className={linkClass({})}>
            Blog
          </Link>
          <Link
            to="/contact"
            data-link-text="Contact"
            className={linkClass({})}
          >
            Contact
          </Link>
          <Link to="/about" data-link-text="About" className={linkClass({})}>
            About
          </Link>
          <a
            key="footer-link--careers"
            href="#careers"
            data-link-text="Careers"
            className={linkClass({})}
          >
            Careers
          </a>
          <div className={legalMenuClass}>
            {legalMenu(legal).map(({ slug, linkText }) => (
              <a
                key={`footer-link--${slug}`}
                href={`#${slug}`}
                className="mobile-menu__link"
              >
                {linkText}
              </a>
            ))}
          </div>
          <a
            href="https://sbgsportssoftware.com/login"
            target="_blank"
            rel="noopener noreferrer"
            data-link-text="Cloud Management Login"
            className={linkClass({})}
          >
            Login
          </a>
        </div>
        <footer className="mobile-menu__footer">
          <SocialLink
            url={instagramUrl || "https://instagram.com"}
            title="Instagram"
            Icon={Instagram}
          />
          <SocialLink
            url={twitterUrl || "https://twitter.com"}
            title="Twitter"
            Icon={Twitter}
          />
        </footer>
      </nav>
    );
  }
}

const SocialLink = ({ url, title, Icon }) => (
  <a
    className="mobile-menu__footer-link"
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <span className="sr-only">{title}</span>
    <Icon color={"currentColor"} />
  </a>
);

export default MobileMenu;
