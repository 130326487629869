import React from "react";

const Banner = ({ text, author, company, classes }) => {
  return (
    <div className={`long-form-banner ${classes}`}>
      <div className={"long-form-banner__text"}>{text}</div>
      <div className={"long-form-banner__author"}>{author}</div>
      <div className={"long-form-company"}>{company}</div>
    </div>
  );
};

export default Banner;
