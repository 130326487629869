import React from "react";
import format from "date-fns/format";
import withLayout from "./withLayout";
import Banner from "../components/banner";
import FollowOnLinks from "../components/FollowOnLinks";
import BackgroundImage from "../components/BackgroundImage";

const BlogSingle = ({
  image,
  icon,
  date,
  title,
  readTime,
  sections,
  shortTitle,
}) => {
  return (
    <div className={"blog"}>
      <header className="long-form-header">
        <div className="long-form-header__image">
          {image && (
            <BackgroundImage
              thumbnailUrl={image.sizes.medium}
              imageUrl={image.url}
              alt={image.alt}
            />
          )}
        </div>
        <div className="long-form-header__info">
          {icon && (
            <img
              className="long-form-header__icon"
              src={icon.url}
              alt={icon.alt}
            />
          )}
          <h1 className="long-form-header__title">{shortTitle || title}</h1>
        </div>
      </header>
      <div className={"blog__content text--dark"}>
        <div className={"blog__content__header"}>
          {title && <h1>{title}</h1>}
          <div className={"blog__information"}>
            <div className={"blog__metadata"}>
              {date && <div>{`Date: ${format(date, "D MMMM YYYY")}`}</div>}
              {readTime && <div>{`Read Time: ${readTime} mins`}</div>}
            </div>
            <FollowOnLinks showLabel={false} />
          </div>
        </div>
        {sections.map((section, index) => {
          return (
            <div key={index} className={"blog__content__section"}>
              {
                <div
                  dangerouslySetInnerHTML={{ __html: section.text }}
                  className={"blog__content__text"}
                />
              }
              {section.banner.text && (
                <Banner
                  text={section.banner.text}
                  author={section.banner.author}
                  company={section.banner.company}
                  classes={"blog__content__banner"}
                />
              )}
              {section.image && (
                <img
                  src={section.image.link}
                  alt={section.image.alt}
                  className={"blog__content__image"}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withLayout(BlogSingle, {
  footer: { shadow: true, lightText: true },
  header: { shadow: false },
});
