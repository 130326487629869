import React, { Component } from "react";

import MediaQuery from "react-responsive";

import MobileNews from "../components/mobile/MobileNews";
import NewsArticles from "../components/News";

import withLayout from "./withLayout";

import { dummyNews } from "../helpers/dummy";
import { toBlog } from "../helpers/parse-data";

import { apiBase } from "../config";

class News extends Component {
  constructor() {
    super();

    this.state = {
      term: "",
      fetching: false,
      results: [],
    };

    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.search("");
  }

  search(term) {
    const { postType } = this.props;
    this.setState({ term, fetching: true });
    fetch(`${apiBase}/wp-json/wp/v2/${postType}?search=${term}`)
      .then((res) => res.json())
      .then((results) => toBlog(results))
      .then((results) => this.setState({ results, fetching: false }))
      .catch(() => this.setState({ fetching: false }));
  }

  render() {
    const { data, options } = this.props;
    const { fetching, term, results } = this.state;

    const allNews = data || dummyNews;
    const coverStory = allNews[0];
    const searchResults = term ? results : allNews;

    return (
      <div>
        <MediaQuery maxWidth={1023}>
          <MobileNews results={allNews} options={options} />
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <NewsArticles
            searchResults={searchResults}
            coverStory={coverStory}
            search={this.search}
            fetching={fetching}
            options={options}
          />
        </MediaQuery>
      </div>
    );
  }
}

export default withLayout(News, { header: { shadow: false } });
