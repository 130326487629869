import React, { Component } from "react";
/*
export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 80 80">
    <path fill="currentColor" d="M30 80L20 70l30-30-30-30L30 0l40 40-40 40z"/>
  </svg>
);
*/

class TheIcon extends Component {

	render() {
		const {
			icon
		} = this.props;

		return(

			<span className={"icon "+icon} ></span>

		);
	};
};

export default TheIcon;