import React, { Component } from "react";
import MediaQuery from "react-responsive";

import About from "../components/About";
import Clients from "../components/Clients";
import ScrollBar from "../components/ScrollBar";
import BackgroundImage from "../components/BackgroundImage";
import AboutCarousel from "../components/AboutCarousel";

import withLayout from "./withLayout";

class AboutPage extends Component {
  render() {
    const { data } = this.props;

    const {
      clientGroups = [{}],
      aboutTitle = "",
      aboutText = "",
      showClients = false,
      aboutCarousel = []
    } = data;

    return (
      <div>
        <MediaQuery maxWidth={1023}>
          <div className="mobile-about">
            <BackgroundImage
              imageUrl={aboutCarousel[0] && aboutCarousel[0].image.sizes.large}
              thumbnailUrl={
                aboutCarousel[0] && aboutCarousel[0].image.sizes.thumbnailUrl
              }
            >
              <header className="mobile-about__header-image">
                <h1 className="mobile-about__title">{aboutTitle}</h1>
              </header>
            </BackgroundImage>
            <div
              className="mobile-about__content"
              dangerouslySetInnerHTML={{ __html: aboutText }}
            />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <div className="about-page__content-wrapper">
            <div className="about-page__clients">
              {showClients ? (
                <Clients clientGroups={clientGroups} />
              ) : (
                <AboutCarousel aboutCarousel={aboutCarousel} />
              )}
            </div>
            <div className="about-page__about">
              <ScrollBar withPadding={true}>
                <About title={aboutTitle} text={aboutText} />
              </ScrollBar>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default withLayout(AboutPage, { footer: { shadow: false, lightText: true }, header: { shadow: false } });
