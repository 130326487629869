import { apiBase } from "../config";

export const buildMenu = (items) => {
  const pick = (item, level) => ({
    id: item.ID,
    title: item.title,
    object: item.object,
    children: [],
    linkTo: item.url === "#" ? undefined : item.url.slice(apiBase.length),
    level,
  });
  const structure = items.reduce((accum, item) => {
    const parentId = parseInt(item.menu_item_parent);
    const itemId = item.ID;
    const parent = accum[parentId];

    if (parentId === 0) {
      // top level
      accum[itemId] = pick(item, 0);
    } else if (parent) {
      parent.children = [...parent.children, pick(item, 1)];
    }

    return accum;
  }, {});

  const nested = items
    .filter((item) => !!structure[item.ID])
    .map((item) => structure[item.ID]);

  const flat = nested.reduce((accum, item) => {
    const children = item.children.map((child) => ({
      ...child,
      title: `${item.title}: ${child.title}`,
    }));
    const itemOrChildren = children.length > 0 ? children : [item];

    return [...accum, ...itemOrChildren];
  }, []);

  return { nested, items: flat };
};

export const toNews = (results) => {
  return results.map((el) => {
    return {
      id: el.id,
      image: el.acf.image,
      date: el.date,
      title: el.title.rendered,
      shortTitle: el.acf.shortTitle,
      content: el.content.rendered,
      slug: el.slug,
      type: el.type,
      /// Added by Andi as seperate content
      youtube_video: el.acf.youtube_video,
    };
  });
};

export const toBlog = (results) => {
  return results.map((el) => {
    return {
      id: el.id,
      image: el.acf.image,
      date: el.date,
      title: el.title.rendered,
      shortTitle: el.acf.shortTitle,
      content: el.content.rendered,
      readTime: el.acf.readTime,
      sections: el.acf.sections,
      author: el.acf.author,
      slug: el.slug,
      type: el.type,
      /// Added by Andi as seperate content
      youtube_video: el.acf.youtube_video,
    };
  });
};

export const toHash = (arr) =>
  arr.reduce((accum, el) => {
    if (el.slug) {
      accum[el.slug] = el.acf;
    }

    return accum;
  }, {});
