import React from "react";

import FollowOnLinks from "../components/FollowOnLinks";

const About = ({ title, text }) => {
  return (
    <div className="about text--dark">
      <h1 className="about__title">{title}</h1>
      <div className="about__text" dangerouslySetInnerHTML={{ __html: text }} />
      <FollowOnLinks pageTitle={title} summary={title} />
    </div>
  );
};

export default About;
