import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import format from "date-fns/format";

import ArrowLeft from "../img/ArrowLeft";
import BackgroundImage from "../BackgroundImage";
import FollowOnLinks from "../FollowOnLinks";

class MobileArticle extends Component {
  constructor() {
    super();

    this.state = {
      scrolled: false
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    window.scroll(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    // px
    const initialHeaderHeight = 210;
    const fixedHeaderHeight = 140;

    const scrolled = scrollTop >= initialHeaderHeight - fixedHeaderHeight;

    this.setState({ scrolled });
  }

  render() {
    const { image, date, title, content, options } = this.props;
    const { scrolled } = this.state;

    const articleClass = classNames("mobile-article", {
      "mobile-article--scrolled": scrolled
    });

    return (
      <article className={articleClass}>
        <Link className="mobile-article__back-btn" to="/news">
          <ArrowLeft />
          Back
        </Link>
        <header className="mobile-article__header">
          <BackgroundImage
            imageUrl={image.sizes.large}
            thumbnailUrl={image.sizes.thumbnail}
          />
        </header>
        <div className="mobile-article__article">
          {!options.noDates && (
            <div className="mobile-article__date p--sm">
              {format(date, "D MMMM YYYY")}
            </div>
          )}
          <div className="mobile-article__title">
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
          <div
            className="mobile-article__content p--sm"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <FollowOnLinks pageTitle={title} summary={title} />
        </div>
      </article>
    );
  }
}

export default MobileArticle;
